<template>
  <div class="menus" style="padding:12px 100px;">
	    <el-form  ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="平台名称" prop="name">
                <el-input v-model="froms.shopname"></el-input>
            </el-form-item>
            <el-form-item label="手机" prop="delivery">
                <el-input  v-model="froms.phone"></el-input>
            </el-form-item>
            <el-form-item label="地址" prop="delivery">
               <el-input  v-model="froms.address"></el-input>
            </el-form-item>
            <el-form-item label="公交路线" prop="delivery">
                <el-input  v-model="froms.busline"></el-input>
            </el-form-item>
            <!-- <el-form :inline="true" style="text-align: left;" label-width="100px" >
               <el-form-item label="经度" prop="delivery" >
                 <el-input  v-model="froms.lng"></el-input>
               </el-form-item>
               <el-form-item label="纬度" prop="delivery">
                 <el-input  v-model="froms.lat"></el-input>
               </el-form-item>
               <el-form-item  prop="delivery">
                <el-button>获取</el-button>
               </el-form-item>
            </el-form> -->
             <!-- <el-form-item label="平台介绍" prop="delivery">
               <el-input  type="textarea" v-model="froms.content"></el-input>
            </el-form-item> -->
            <el-form-item label="注册协议" >
                <el-input  v-model="froms.protocol"></el-input>
            </el-form-item>
            <el-form-item label="提示搜索词" >
                <el-input  v-model="froms.keyword"></el-input>
            </el-form-item>
            <el-form-item label="tag词" >
                <el-input  v-model="froms.tag"></el-input>
            </el-form-item>
            <el-form-item label="是否关闭" >
                <el-radio-group v-model="froms.status">
                  <el-radio  label="1">开启</el-radio>
                  <el-radio  label="2">关闭</el-radio>
                </el-radio-group>
            </el-form-item>

            <!-- <wang-editor ref="ch" :msg="msg"></wang-editor> -->
            <!-- 富文本 -->
            <HEllo v-model="detail"  :value="value" :isClear="isClear" @change="change"></HEllo>

            <el-form-item>
                <el-button type="primary" style="margin-top:15px;" @click="submitForm()">保存</el-button>
                <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
            </el-form-item>
        </el-form>
     
  </div>
  
</template>



<script>
import request from '@/utils/request.js'
import HEllo from '../../components/HelloWorld.vue'
  export default {
    components:{
        HEllo
    },
    data() {
      return {
        msg:'',
        value:'',
        htmls:'',
        froms:[],
        isClear: false,
        detail:""
      }
    },
    created() {
      this.info();
    },
    
     methods:{
       //  富文本事件
          change(val) {
            // console.log(val)
            this.value = val
            // console.log(this.value)
          },
       dataChange(){
            // let msg = this.froms.content;
            
            // console.log(this.msg)
      },
         info(){
             return request({
                  url:'config',
                  method:'get',
              }).then(res=>{
                if(res.status === 200){
                   this.froms = res.data;
                  //  this.dataChange();
                    this.detail = this.froms.content;
                    console.log(this.froms);
                }
              })
         },
         submitForm() {
             let ch = this.$refs.ch;                 
             // console.log(ch);
             let datas ={
                shopname:this.froms.shopname,
                address:this.froms.address,
                busline:this.froms.busline,
                keyword:this.froms.keyword,
                lat:this.froms.lat,
                lng:this.froms.lng,
                phone:this.froms.phone,
                status:this.froms.status,
                tag:this.froms.tag,
                tel:this.froms.tel,
                content:this.value,
             }
            //  console.log(this.froms)
       
              return request({
                  url:'config',
                  method:'put',
                  data:datas
              }).then(res=>{
                if(res.status === 200){
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.info();
              
                }
              })
         },
    },
  }
</script>

<style scoped>
  .el-table .warning-row {
     background: oldlace;
  }

  .el-table .success-row {
     background: #f0f9eb;
  }
  .tian{
        width: 100%;
        height: 60px;
   }
  .el-dialog{
      width: 500px !important;
  }
  .el-form{

        width: auto;
        height: auto;
       
    }
  .el-pagination{
    text-align: right;
  }
</style>
 
